<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs8 sm8 md8 lg6 xl6>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="usuarioLocal.nome"
                    :error-messages="comNomeErrors"
                    label="Nome"
                    required
                    outlined
                    @input="$v.usuarioLocal.nome.$touch()"
                    @blur="$v.usuarioLocal.nome.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="10" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="usuarioLocal.email"
                    :error-messages="comEmailErrors"
                    label="E-mail"
                    required
                    outlined
                    @input="$v.usuarioLocal.email.$touch()"
                    @blur="$v.usuarioLocal.email.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="10" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="usuarioLocal.whatsapp"
                    :error-messages="comWhatsappErrors"
                    label="Whatsapp"
                    v-mask="'+## (##) #####-####'"
                    required
                    outlined
                    @input="$v.usuarioLocal.whatsapp.$touch()"
                    @blur="$v.usuarioLocal.whatsapp.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="usuarioLocal.usuario"
                    :error-messages="comUsuarioErrors"
                    label="Usuario"
                    required
                    outlined
                    @input="$v.usuarioLocal.usuario.$touch()"
                    @blur="$v.usuarioLocal.usuario.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="usuarioLocal.senha"
                    :error-messages="comSenhaErrors"
                    label="Senha"
                    required
                    outlined
                    readonly
                    @input="$v.usuarioLocal.senha.$touch()"
                    @blur="$v.usuarioLocal.senha.$touch()"
                  >
                    <template v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="metGeneratePassword"
                          >
                            mdi-refresh
                          </v-icon>
                        </template>
                        <span>Gerar Senha</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-row>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4 mr-4 mb-4"
                    color="green darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSubmit"
                  >
                    mdi-check-circle
                  </v-icon>
                </template>
                <span>Salvar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 mb-4"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-backspace
                  </v-icon>
                </template>
                <span>Cancelar</span>
              </v-tooltip>
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 mb-4"
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                    @click="metEnviarDadosPorEmail"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span>Enviar dados por e-mail</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, helpers } from "vuelidate/lib/validators";

const { mapState, mapActions } = createNamespacedHelpers("adminUsuarios");

export default {
  mixins: [validationMixin],
  validations: {
    usuarioLocal: {
      nome: { required },
      email: { required, email },
      whatsapp: {
        required,
        isPhoneNumber: helpers.regex(
          "isPhoneNumber",
          /^\+\d{2} \(\d{2}\) \d{5}-\d{4}$/
        ),
      },
      usuario: { required },
      senha: { required },
      ativo: { required },
    },
  },

  data: () => ({
    tituloForm: "",
    usuarioLocal: {},
    selectedGrupo: {},
  }),

  computed: {
    ...mapState(["staUsuarioSelecionado"]),

    comNomeErrors() {
      const errors = [];
      if (!this.$v.usuarioLocal.nome.$dirty) return errors;
      if (!this.usuarioLocal.nome || this.usuarioLocal.nome.length < 10) {
        errors.push("O nome de usuário deve ter pelo menos 10 caracteres.");
      }
      return errors;
    },

    comEmailErrors() {
      const errors = [];
      if (!this.$v.usuarioLocal.email.$dirty) return errors;
      !this.$v.usuarioLocal.email.email && errors.push("E-mail inválido");
      !this.$v.usuarioLocal.email.required && errors.push("E-mail requerido");
      return errors;
    },

    comWhatsappErrors() {
      const errors = [];
      if (!this.$v.usuarioLocal.whatsapp.$dirty) return errors;
      if (
        this.usuarioLocal.whatsapp &&
        !this.$v.usuarioLocal.whatsapp.isPhoneNumber
      ) {
        errors.push(
          "Formato de número de celular inválido. Exemplo: +55 (11) 91234-5678"
        );
      }
      return errors;
    },

    comUsuarioErrors() {
      const errors = [];
      if (!this.$v.usuarioLocal.usuario.$dirty) return errors;
      if (!this.usuarioLocal.usuario || this.usuarioLocal.usuario.length < 6) {
        errors.push("O usuário deve ter pelo menos 6 caracteres.");
      }
      return errors;
    },

    comSenhaErrors() {
      const errors = [];
      if (!this.$v.usuarioLocal.senha.$dirty) return errors;
      !this.$v.usuarioLocal.senha.required && errors.push("Senha requerida.");
      return errors;
    },

    comGrupoLista() {
      return this.$store.state.adminUsuarios.listaGrupos;
    },
  },
  watch: {
    staUsuarioSelecionado(usuarioNovo) {
      this.metSincronizar(usuarioNovo);
    },
  },
  created() {
    this.metSincronizar(this.staUsuarioSelecionado);
    this.staUsuarioSelecionado
      ? (this.tituloForm = "Editar Usuário")
      : (this.tituloForm = "Novo Usuário");
  },
  methods: {
    ...mapActions(["actCriarUsuario", "actEditarUsuario", "actEnviarEmail"]),

    async metEnviarDadosPorEmail() {
      const emailData = {
        from: `${this.$store.state.login.usuario.nome} <${this.$store.state.login.usuario.email}>`,
        to: this.usuarioLocal.email,
        subject: `[Clomos] Dados de Acesso - ${this.$store.state.login.licenca}`,
        html: `
    <div style="font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; max-width: 600px; margin: auto;">
      <div style="background-color: #004d99; color: white; padding: 10px; text-align: center; font-size: 24px;">
        Clomos
      </div>
      <div style="border: 1px solid #ddd; padding: 20px; background-color: #f9f9f9;">
        <h1 style="color: #333;">Dados de Acesso</h1>
        <p>Olá, <strong>${this.usuarioLocal.nome}</strong></p>
        <p>Aqui estão seus dados de acesso:</p>
        <p><strong>Usuário:</strong> ${this.usuarioLocal.usuario}</p>
        <p><strong>Senha:</strong> ${this.usuarioLocal.senha}</p>
        <p><strong>Link de Acesso:</strong> <a href="https://${this.$store.state.login.licenca}.clomos.com.br" target="_blank">https://${this.$store.state.login.licenca}.clomos.com.br</a></p>
        <p style="color: #555;">Por favor, não compartilhe essas informações.</p>
      </div>
      <div style="background-color: #004d99; color: white; padding: 10px; text-align: center; font-size: 18px;">
        Rotnet - Sistemas Avançados
      </div>
    </div>
  `,
      };

      try {
        const response = await this.actEnviarEmail(emailData);
        this.$root.snackbar.show({
          type: "info",
          message: response.response,
        });
        if (response.response.startsWith("250")) {
          this.$root.snackbar.show({
            type: "success",
            message: "E-mail enviado com sucesso!",
          });
        }
      } catch (erro) {
        this.$root.snackbar.show({
          type: "error",
          message: erro.response,
        });
      }
    },

    metLimparNumeroTelefone(numero) {
      if (!numero) return "";
      return numero.replace(/[^\d+]/g, "");
    },

    metGeneratePassword() {
      const length = 8;
      const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
      let password = "";
      for (let i = 0; i < length; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      this.$set(this.usuarioLocal, "senha", password);
    },

    metSalvarUsuario() {
      this.usuarioLocal.nome = this.usuarioLocal.nome.trim();
      this.usuarioLocal.email = this.usuarioLocal.email.trim();
      this.usuarioLocal.usuario = this.usuarioLocal.usuario.trim();
      this.usuarioLocal.senha = this.usuarioLocal.senha.trim();

      let dadosParaSalvar = Object.assign({}, this.usuarioLocal);
      dadosParaSalvar.whatsapp = this.metLimparNumeroTelefone(
        dadosParaSalvar.whatsapp
      );

      switch (this.staUsuarioSelecionado) {
        case undefined:
          this.actCriarUsuario({
            usuario: dadosParaSalvar,
            licenca: this.$store.state.login.licenca,
          });
          this.$root.snackbar.show({
            type: "success",
            message: "Usuário Criado!",
          });
          this.tituloForm = "Editar Usuário";
          break;
        default:
          this.actEditarUsuario({
            usuario: dadosParaSalvar,
            licenca: this.$store.state.login.licenca,
          });
          this.$root.snackbar.show({
            type: "success",
            message: "Usuário Salvo!",
          });
      }
      //this.resetar();
    },

    metSincronizar(novoUsuario) {
      this.usuarioLocal = Object.assign(
        {},
        novoUsuario || {
          id: 0,
          nome: "",
          email: "",
          usuario: "",
          senha: "",
          ativo: 1,
        }
      );
    },
    metSubmit() {
      this.$v.$touch();
      this.metSalvarUsuario();
    },
    metClear() {
      this.usuarioLocal.nome = this.staUsuarioSelecionado.nome;
      this.usuarioLocal.email = this.staUsuarioSelecionado.email;
      this.usuarioLocal.usuario = this.staUsuarioSelecionado.usuario;
      this.usuarioLocal.senha = this.staUsuarioSelecionado.senha;
      this.usuarioLocal.ativo = this.staUsuarioSelecionado.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
